import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#F28705",
      main: "#F28600",
      dark: "#F28705",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF6035",
      light1: "#fff",
      dimLight: "#5ED3CB",
      main: "#153959",
      contrastText: "#fff",
    },
    tertiary: {
      light: "#ff4081",
      main: "#f50057",
      dark: "#c51162",
      contrastText: "#fff",
    },
    fontFamily: {
      Roboto: "Roboto, Sans-serif",
      Reef: "Reef",
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;

// let theme = createMuiTheme({
//   palette: {
//     type: "dark",
//     primary: {
//       light: "#F28705",
//       main: "#F28600",
//       dark: "#F28705",
//       contrastText: "#fff",
//     },
//     secondary: {
//       light: "#09A9BB",
//       dimLight: "#5ED3CB",
//       main: "#153959",
//       contrastText: "#fff",
//     },
//     tertiary: {
//       light: "#ff4081",
//       main: "#f50057",
//       dark: "#c51162",
//       contrastText: "#fff",
//     },
//     fontFamily: {
//       Roboto: "Roboto, Sans-serif",
//       Reef: "Reef",
//     },
//   },
// })
